







































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  NotifyTemplateCreateOrUpdateDto,
  CmsCategoryType,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
  },
})
export default class CreateNotify extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: NotifyTemplateCreateOrUpdateDto = {
    title: undefined,
    content: undefined,
    id: 0,
  };
  typeList: any = [];

  created() {
    if (this.$route.params.id) {
      api.notifyTemplate
        .get({ id: Number(this.$route.params.id) })
        .then((res) => {
          this.form = { ...res };
        });
    }
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "NotifyTemplateType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }
  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.notifyTemplate.update({
            body: this.form,
          });
        } else {
          await api.notifyTemplate.create({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success("更新成功");
      }
    });
  }

  cancel() {
    this.$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }

  roleRule = {
    title: [
      {
        required: true,
        message: "通知模板名称必填",
        trigger: "blur",
      },
    ],
    notifyTemplateType: [
      {
        required: true,
        message: "通知模板类型必填",
        trigger: "blur",
      },
    ],
  };
}
